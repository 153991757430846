import React, { forwardRef } from "react";
import "../css/hero.css";

const Hero = forwardRef((props, ref) => {
  return (
    <section id="hero-section" ref={ref}>
      <article>
        <h2>
          <span id="h2-sp1">Endless Opportunities</span>
          <br />
          <span id="h2-sp2">for Every Talent.</span>
        </h2>
        <p>
          We're all about tech and we're all about helping. Taking you further,
          and providing you with endless possibilities in tech.
        </p>
        <button
          className="join-waitlist"
          onClick={() => props.openWaitlistForm()}
        >
          {/* */}
          <span></span>
          <span>Join waitlist</span>
        </button>
      </article>
      <figure id="banner"></figure>
    </section>
  );
});

export default Hero;
