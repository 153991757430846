import React, { forwardRef, useEffect, useRef, useState } from "react";
import NavLogo from "../assets/sm-logo.svg";
import Close from "../assets/icons/close.svg";
import DdArrow from "../assets/icons/dd-arrow.svg";
import "../css/waitlist-form.css";

const WaitlistForm = forwardRef((props, ref) => {
  // sets data and data state for the dropdown
  const [ShowTracks, setShowTracks] = useState(false);
  const [ShowLevels, setShowLevels] = useState(false);
  const [levelData, setLevelData] = useState("");
  const [trackData, setTrackData] = useState("");
  // sets the refs for the input and the input's dropdown
  const fullName = useRef();
  const email = useRef();
  const levelInput = useRef();
  const trackInput = useRef();
  const levelInputDD = useRef();
  const trackInputDD = useRef();

  // toggles the display of the form dropdown
  useEffect(() => {
    ref.current.addEventListener("click", (e) => {
      if (
        !levelInput.current.contains(e.target) &&
        !levelInputDD.current.contains(e.target)
      ) {
        setShowLevels((ShowLevels) => false);
      }
      if (
        !trackInput.current.contains(e.target) &&
        !trackInputDD.current.contains(e.target)
      ) {
        setShowTracks((ShowLevels) => false);
      }
    });
  }, [ref]);

  // this sets the value of the levels and tracks input, once a list in the dropdown is clicked
  const setLevel = (levelVal) => {
    levelInput.current.value = levelVal;
  };
  const setTrack = (trackVal) => {
    trackInput.current.value = trackVal;
  };

  // this functions submits the form after validation is successful
  const submitWaitlistForm = () => {
    const waitlistFormData = {
      "full-name": fullName.current.value,
      email: email.current.value,
      level: levelData,
      track: trackData,
    };
    fetch("sendWaitlistForm.php", {
      method: "POST",
      body: JSON.stringify(waitlistFormData),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        window.location.replace("./");
        //if(response == true) {
        //openWaitlistSuccessModal(true);
        //} else {
        //openWaitlistSuccessModal(false);
        //}
      })
      .catch((error) => console.log(error));
  };

  return (
    <section id="waitlist-form" ref={ref}>
      <img
        id="closeWaitlistForm"
        src={Close}
        alt=""
        onClick={() => props.closeWaitlistForm()}
      />
      <img id="waitlist-nav-logo" src={NavLogo} alt="Devpartner's logo" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("submitted");
          submitWaitlistForm();
        }}
      >
        <div>
          <label htmlFor="full-name">Full Name</label>
          <input
            name="fullname"
            id="full-name"
            type="text"
            placeholder="Enter your full name"
            required="required"
            ref={fullName}
          />
        </div>
        <div>
          <label htmlFor="email">Email Address</label>
          <input
            name="email"
            id="email"
            type="email"
            placeholder="Enter your email address"
            required="required"
            ref={email}
          />
        </div>
        <div>
          <label htmlFor="track">Preferred Track</label>
          <input
            name="track"
            onInput={(e) => {
              e.target.value = "";
            }}
            type="text"
            id="track"
            placeholder="Enter your preferred track"
            ref={trackInput}
            required="required"
          />
          <img
            src={DdArrow}
            alt=""
            className="dd-arrow"
            ref={trackInputDD}
            onClick={() => setShowTracks((ShowTracks) => !ShowTracks)}
          />
          <ul className={ShowTracks ? "showDD" : "hideDD"}>
            <li
              onClick={() => {
                setTrack("Data Analytics");
                setTrackData("Data Analytics");
              }}
            >
              Data Analytics
            </li>
            <li
              onClick={() => {
                setTrack("Cyber Security");
                setTrackData("Cyber Security");
              }}
            >
              Cyber Security
            </li>
            <li
              onClick={() => {
                setTrack("AI & Robotics");
                setTrackData("AI & Robotics");
              }}
            >
              AI &amp; Robotics
            </li>
            <li
              onClick={() => {
                setTrack("Blockchain Technology");
                setTrackData("Blockchain Technology");
              }}
            >
              Blockchain Technology
            </li>
          </ul>
        </div>
        <div>
          <label htmlFor="level">Level</label>
          <input
            name="level"
            onInput={(e) => {
              e.target.value = "";
            }}
            type="text"
            id="level"
            placeholder="Enter your level"
            ref={levelInput}
            required="required"
          />
          <img
            src={DdArrow}
            alt=""
            className="dd-arrow"
            ref={levelInputDD}
            onClick={() => {
              setShowLevels((ShowLevels) => !ShowLevels);
            }}
          />
          <ul className={ShowLevels ? "showDD" : "hideDD"}>
            <li
              onClick={() => {
                setLevel("Beginner");
                setLevelData("Beginner");
              }}
            >
              Beginner
            </li>
            <li
              onClick={() => {
                setLevel("Intermediate");
                setLevelData("Intermediate");
              }}
            >
              Intermediate
            </li>
            <li
              onClick={() => {
                setLevel("Expert");
                setLevelData("Expert");
              }}
            >
              Expert
            </li>
          </ul>
        </div>
        <button type="submit" className="join-waitlist">
          <span></span>
          <span>Submit</span>
        </button>
      </form>
    </section>
  );
});

export default WaitlistForm;
