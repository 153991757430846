import React from "react";
import "../css/section2.css";
import Ill1 from "../assets/images/section-2-ill-1.jpg";
import Ill2 from "../assets/images/section-2-ill-2.jpg";
import Ill3 from "../assets/images/section-2-ill-3.jpg";
import Ill4 from "../assets/images/section-2-ill-4.jpg";

const Section2 = () => {
  return (
    <section id="section2">
      <article className="odd">
        <div>
          <img src={Ill1} alt="Internships" />
        </div>
        <article>
          <h2>Empower your future</h2>
          <h2>Internships</h2>
          <p>
            The Internship is designed to surface the very best of candidates
            out of a large pool of participants. It is fast paced and
            challenging, and helps people learn how to work in teams, deal with
            pressure and quickly expand their knowledge.
          </p>
          <ul>
            <li>Long-term placements</li>
            <li>Competitive compensation</li>
            <li>Career guidance</li>
          </ul>
        </article>
      </article>
      <article className="even">
        <div>
          <img src={Ill2} alt="Trainings" />
        </div>
        <article>
          <h2>GAIN KNOWLEDGE</h2>
          <h2>Trainings</h2>
          <p>
            Here at DevPartners Africa, you gain access to enough knowledge to
            introduce and aid your success in the tech industry. We connect you
            with like minds to grow together. 100% online classes available to
            anyone in the world.
          </p>
          <ul>
            <li>Hands-on Experience</li>
            <li>Beginner Friendly</li>
            <li>In-program Mentorship</li>
            <li>Real-life Projects</li>
            <li>Collaborative Learning</li>
          </ul>
        </article>
      </article>
      <article className="odd">
        <div>
          <img src={Ill3} alt="Idea Incubation" />
        </div>
        <article>
          <h2>BUILD YOUR DREAM TEAM</h2>
          <h2>Idea Incubation</h2>
          <p>
            Skilled engineers, data analysts, and AI experts at your fingertips.
            An extensive list of technical services to suit your business needs.
            Start building your world class team faster with talent from
            DevPartners Africa.
          </p>
          <ul>
            <li>Smart skill matching</li>
            <li>Meet the best talent</li>
            <li>Continued excellence</li>
            <li>Easily schedule interviews</li>
            <li>Intuitive machine learning platform</li>
          </ul>
        </article>
      </article>
      <article className="even">
        <div>
          <img src={Ill4} alt="Talent Outsourcing" />
        </div>
        <article>
          <h2>Find talent your way</h2>
          <h2>Talent Outsourcing</h2>
          <p>
            Work with the largest network of independent professionals and get
            things done from quick turnarounds to big transformations. An
            efficient way to hire highly-qualified talent and with very little
            effort on your part.
          </p>
          <ul>
            <li>Proof of quality</li>
            <li>High performing tech talents</li>
            <li>Safe and secure</li>
            <li>No cost until you hire</li>
          </ul>
        </article>
      </article>
    </section>
  );
};

export default Section2;
