import React from "react";
import "../css/section3.css";
import AnalyticsImg from "../assets/images/analytics.png";
import AiImg from "../assets/images/ai.png";
import BlockchainImg from "../assets/images/blockchain.png";
import CybersecurityImg from "../assets/images/cybersecurity.png";

const Section3 = (props) => {
  return (
    <section id="section3">
      <article>
        <h2>
          <span className="h2-sp1">Endless Opportunities</span>
          <br />
          <span className="h2-sp2">for Every Talent.</span>
        </h2>
        <p>
          Our promise is quality talent that has the perfect fit for your
          engineering team. Hire the top 0.5% of developers in Africa, 100%
          ready to help you scale quickly and build efficiently. We connect the
          world's top talent with the world's top organizations.
        </p>
      </article>
      <div id="card-cnt">
        <div className="card">
          <img src={AnalyticsImg} alt="Data Analytics" />
          <div className="card-overlay"></div>
          <h3>Data Analytics</h3>
          <p>
            Data analytics is significant for top organisations. Data
            specialists have a tremendous impact on business strategies.
            Companies are now expecting their business decisions to be based on
            data-led insight.
          </p>
        </div>
        <div className="card">
          <img src={AiImg} alt="Artificial Intelligence" />
          <div className="card-overlay"></div>
          <h3>AI &amp; Robotics</h3>
          <p>
            Robotics and artificial intelligence engineering is an
            interdisciplinary engineering field that combines principles of
            mechanical, electrical, computer, system, and mechatronics
            engineering.
          </p>
        </div>
        <div className="card">
          <img src={BlockchainImg} alt="Blockchain Technology" />
          <div className="card-overlay"></div>
          <h3>Blockchain Technology</h3>
          <p>
            Blockchain increases security, transparency, and the traceability of
            data shared across a business network and delivers cost savings with
            new efficiencies.
          </p>
        </div>
        <div className="card">
          <img src={CybersecurityImg} alt="Cyber Security" />
          <div className="card-overlay"></div>
          <h3>Cyber Security</h3>
          <p>
            Cyber security is one of the most important aspects of the
            fast-paced growing digital world. Cybersecurity is important because
            not only it helps to secure information but also our system from
            virus attack.
          </p>
        </div>
      </div>
      <div id="btn-cnt">
        <button
          className="join-waitlist"
          onClick={() => props.openWaitlistForm()}
        >
          {/*  */}
          <span></span>
          <span>Join waitlist</span>
        </button>
      </div>
    </section>
  );
};

export default Section3;
