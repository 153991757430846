import React from "react";
import "../css/partners.css";
import Carmel from "../assets/images/carmel-logo.png";
import SpaceUniverse from "../assets/images/space-universe-logo.png";
import PopAdlink from "../assets/images/PopAdLink-logo.png";
import RadioAdspread from "../assets/images/radioadspread-logo.png";

const Partners = () => {
  return (
    <section id="partners">
      <h2>Supported by the best</h2>
      <figure>
        <img src={Carmel} alt="Carmel's logo" />
        <img src={SpaceUniverse} alt="Space Universe's logo" />
        <img src={PopAdlink} alt="PopAdlink's logo" />
        <img src={RadioAdspread} alt="RadioAdpread's logo" />
      </figure>
    </section>
  );
};

export default Partners;
