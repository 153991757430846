import React, { forwardRef } from "react";
import '../css/footer.css';
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg";
import { ReactComponent as LinkedIn } from "../assets/icons/linkedin.svg";

const Footer = forwardRef((props, ref) => {
  return (
    <footer ref={ref}>
      <div>
        <ul>
          <li>
            <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
              Terms of Service
            </a>
          </li>
          <li>
            <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
              Cookie Policy
            </a>
          </li>
          <li>
            <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
              Imprint
            </a>
          </li>
        </ul>
      </div>
      <div>
        <span>Connect with us</span>
        <span id="socials">
          <span className="footer-icon">
            <Instagram />
          </span>
          <span className="footer-icon">
            <Twitter />
          </span>
          <span className="footer-icon">
            <LinkedIn />
          </span>
        </span>
      </div>
      <address>
        <span>2b Montgomery Rd, Yaba, Lagos, Nigeria</span>
        <span>Hi@Spaceuniverse.africa</span>
        <span>+2348153673830</span>
      </address>
    </footer>
  );
});

export default Footer;