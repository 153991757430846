import React from "react";
import "../css/section5.css";
import JoinImg from "../assets/images/join-us.png";

const Section5 = () => {
  return (
    <section id="section5">
      <img src={JoinImg} alt="a group of people collaborating" />
      <div className="overlay"></div>
      <article>
        <h2>Collaborate with us</h2>
        <p>
          Interested in inspiring the next generation of top Tech talents?
          Collaborate with us.
          <br /> We are committed to helping as many young Africans kickstart a
          career in technology by providing them with access to quality
          technical education and working experience by real world experts.
        </p>
        <button className="join-waitlist">
          <span></span>
          <span>Contact Us</span>
        </button>
      </article>
    </section>
  );
};

export default Section5;
