import React from "react";
import "../css/section4.css";

const Section4 = () => {
  return (
    <section id="section4">
      <div>
        <div>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
          <span id="primary">Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
        </div>
        <div>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
          <span>Start. Stop. Focus</span>
        </div>
      </div>
    </section>
  );
};

export default Section4;
