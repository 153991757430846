import React, { forwardRef } from "react";
import "../css/navbar.css";
import NavLogo from "../assets/sm-logo.svg";
import Menu from "../assets/icons/menu.svg";
import Close from "../assets/icons/close.svg";
import BtnArrow from "../assets/icons/btn-arrow-right.svg";

const NavBar = forwardRef((props, ref) => {
  return (
    <nav id="header-nav" ref={ref}>
      <div id="sm-nav">
        <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
          <img src={NavLogo} alt="logo" />
        </a>
        <img
          id="menu-btn"
          src={Menu}
          alt=""
          onClick={() => {
            document.querySelector("#sm-nav > div").style.width = "100%";
            document.querySelector("#sm-nav > div").style.opacity = "1";
          }}
        />
        <div>
          <img
            id="close-btn"
            src={Close}
            alt=""
            onClick={() => {
              document.querySelector("#sm-nav > div").style.width = "0px";
              document.querySelector("#sm-nav > div").style.opacity = "0";
            }}
          />
          <ul>
            <li>
              <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
                who we are
              </a>
            </li>
            <li>
              <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
                what we do
              </a>
            </li>
            <li>
              <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
                contact us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ul id="lg-nav">
        <li>
          <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
            who we are
          </a>
        </li>
        <li>
          <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
            what we do
          </a>
        </li>
        <li>
          <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
            <img src={NavLogo} alt="logo" />
          </a>
        </li>
        <li>
          <a href="#" /*eslint-disable-line jsx-a11y/anchor-is-valid*/>
            contact us
          </a>
        </li>
        <li>
          <button
            className="join-waitlist"
            onClick={() => props.openWaitlistForm()}
          >
            {/*  */}
            <span></span>
            <span>
              <span>Join Waitlist</span>
              <img src={BtnArrow} alt="" />
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
});

export default NavBar;
