import React, { useRef } from "react";
import "./App.css";
import NavBar from "./components/Navbar";
import Hero from "./components/Hero";
import Partners from "./components/Partners";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Footer from "./components/Footer";
import WaitlistForm from "./components/WaitlistForm";

function App() {
  const navRef = useRef();
  const heroRef = useRef();
  const mainRef = useRef();
  const footerRef = useRef();
  const waitlistRef = useRef();

  const openWaitlistForm = () => {
    waitlistRef.current.style.width = "100%";
    waitlistRef.current.style.opacity = "1";
    navRef.current.style.display = "none";
    heroRef.current.style.display = "none";
    mainRef.current.style.display = "none";
    footerRef.current.style.display = "none";
    window.scrollTo({ top: 0 });
  };

  const closeWaitlistForm = () => {
    waitlistRef.current.style.width = "0px";
    waitlistRef.current.style.opacity = "0";
    navRef.current.style.display = "block";
    heroRef.current.style.display = "block";
    mainRef.current.style.display = "block";
    footerRef.current.style.display = "flex";
  };

  return (
    <div className="App">
      <NavBar ref={navRef} openWaitlistForm={openWaitlistForm} />
      <Hero ref={heroRef} openWaitlistForm={openWaitlistForm} />
      <main ref={mainRef}>
        <Partners />
        <Section2 />
        <Section3 openWaitlistForm={openWaitlistForm} />
        <Section4 />
        <Section5 />
      </main>
      <aside id="forms">
        <WaitlistForm
          id="waitlist-form"
          closeWaitlistForm={closeWaitlistForm}
          ref={waitlistRef}
        />
      </aside>
      <Footer ref={footerRef} />
    </div>
  );
}

export default App;
